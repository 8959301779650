import { NotificationManager } from "../../components/common/react-notifications";
import {
    GRUPOS_NOMINA_LIST_GET_LIST,
    GRUPOS_NOMINA_LIST_GET_LIST_SUCCESS,
    GRUPOS_NOMINA_LIST_GET_LIST_ERROR,
    GRUPOS_NOMINA_LIST_GET_LIST_WITH_FILTER,
    GRUPOS_NOMINA_LIST_GET_LIST_WITH_ORDER,
    GRUPOS_NOMINA_LIST_GET_LIST_SEARCH,
    GRUPOS_NOMINA_LIST_ADD_ITEM,
    GRUPOS_NOMINA_LIST_ADD_ITEM_SUCCESS,
    GRUPOS_NOMINA_LIST_ADD_ITEM_ERROR,
    GRUPOS_NOMINA_LIST_SELECTED_ITEMS_CHANGE
} from '../actions';

const INIT_STATE = {
	allGruposNominasItems: [],
	gruposNominasItems: [],
	errors: {},
	filter: null,
	searchKeyword: '',
	orderColumn: null,
	loading: false,
	orderColumns: [
		{ column: "grupo", label: "Grupo de nómina" },
		{ column: "tipo", label: "Tipo de nómina" },
		{ column: "periodicidad", label: "Periodicidad" },
        { column: "num_Personas", label: "Núm. de personas" },
        { column: "color", label: "Color" },
        { column: "master", label: "Master" },
	],
    tiposDeNomina: [
        { label: "Ordinaria", value: 0},
        { label: "Extra Ordinaria", value: 1},
        
      ],
    tipoDePeriodicidad: [
        {label:"Semanal", value: 0},
        {label:"Quincenal", value: 1},
        {label:"Mensual", value: 2},
    ],
	selectedItems: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case GRUPOS_NOMINA_LIST_GET_LIST:
            return { ...state, loading: false };
        case GRUPOS_NOMINA_LIST_GET_LIST_SUCCESS:
            console.log("success", action);
            return { ...state, loading: true, allGruposNominasItems: action.payload.data, gruposNominasItems: action.payload.data };
        case GRUPOS_NOMINA_LIST_GET_LIST_ERROR:
            console.log("error", action);
            NotificationManager.error(action.payload, "Error", 5000, () => { }, null, 'filled');
            return { ...state, loading: true, error: action.payload };
        case GRUPOS_NOMINA_LIST_GET_LIST_WITH_FILTER:
			if (action.payload.column === '' || action.payload.value === '') {
				return { ...state, loading: true, gruposNominasItems: state.allGruposNominasItems, filter: null };
			} else {
				const filteredItems = state.allGruposNominasItems.filter((item) =>
					item[action.payload.column] === action.payload.value);
				return {
					...state, loading: true, gruposNominasItems: filteredItems, filter: {
						column: action.payload.column,
						value: action.payload.value
					}
				}
            }
            case GRUPOS_NOMINA_LIST_GET_LIST_WITH_ORDER:
                if (action.payload === '') {
                    return { ...state, loading: true, gruposNominasItems: state.gruposNominasItems, orderColumn: null };
                } else {
                    const sortedItems = state.gruposNominasItems.sort((a, b) => {
                        if (
                            a[action.payload] <
                            b[action.payload]
                        )
                            return -1;
                        else if (
                            a[action.payload] >
                            b[action.payload]
                        )
                            return 1;
                        return 0;
                    })
                    return { ...state, loading: true, gruposNominasItems: sortedItems, orderColumn: state.orderColumns.find(x => x.column === action.payload) }
                }
            case GRUPOS_NOMINA_LIST_GET_LIST_SEARCH:
                if (action.payload === '') {
                    return { ...state, gruposNominasItems: state.allGruposNominasItems };
                } else {
                    const keyword = action.payload.toLowerCase();
                    const searchItems = state.allGruposNominasItems.filter((item) =>
                        item.grupo.toLowerCase().indexOf(keyword) > -1 || item.tipo.toLowerCase().indexOf(keyword) > -1 || item.periodicidad.toLowerCase().indexOf(keyword) > -1 || item.Núm_de_personas.toLowerCase().indexOf(keyword) > -1 || item.Color.toLowerCase().indexOf(keyword) > -1  ); //agregar mas || item.status.toLowerCase().indexOf(keyword) > -1 || item.category.toLowerCase().indexOf(keyword) > -1 || item.label.toLowerCase().indexOf(keyword) > -1
                    return { ...state, loading: true, gruposNominasItems: searchItems, searchKeyword: action.payload }
                }
            case GRUPOS_NOMINA_LIST_ADD_ITEM:
                return { ...state, loading: false };    
            
            case GRUPOS_NOMINA_LIST_ADD_ITEM_SUCCESS:
                return { ...state, loading: true, allGruposNominasItems: action.payload, gruposNominasItems: action.payload };
            
            case GRUPOS_NOMINA_LIST_ADD_ITEM_ERROR:
                return { ...state, loading: true, error: action.payload };
            
            case GRUPOS_NOMINA_LIST_SELECTED_ITEMS_CHANGE:
                    return { ...state, loading: true, selectedItems: action.payload};
            
            default: return { ...state };
    }
}