import {
    CHANGE_LOCALE
} from '../actions';
import {
    NOMINAS_LIST_GET_LIST,
    NOMINAS_LIST_GET_LIST_SUCCESS,
    NOMINAS_LIST_GET_LIST_ERROR,
    NOMINAS_LIST_GET_LIST_WITH_FILTER,
    NOMINAS_LIST_GET_LIST_WITH_ORDER,
    NOMINAS_LIST_GET_LIST_SEARCH,
    NOMINAS_LIST_ADD_ITEM,
    NOMINAS_LIST_ADD_ITEM_SUCCESS,
    NOMINAS_LIST_ADD_ITEM_ERROR,
    NOMINAS_LIST_SELECTED_ITEMS_CHANGE
} from '../actions';

export const getNominaList = () => ({
    type: NOMINAS_LIST_GET_LIST
});

export const getNominaListSuccess = (items) => ({
    type: NOMINAS_LIST_GET_LIST_SUCCESS,
    payload: items
});

export const getNominaListError = (error) => ({
    type: NOMINAS_LIST_GET_LIST_ERROR,
    payload: error
});

export const getNominaListWithFilter = (column, value) => ({
    type: NOMINAS_LIST_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

export const getNominaListWithOrder = (column) => ({
    type: NOMINAS_LIST_GET_LIST_WITH_ORDER,
    payload: column
});

export const getNominaListSearch = (keyword) => ({
    type: NOMINAS_LIST_GET_LIST_SEARCH,
    payload: keyword
});
export const addNominaItem = (item) => ({
    type: NOMINAS_LIST_ADD_ITEM,
    payload: item
});

export const addNominaItemSuccess = (items) => ({
    type: NOMINAS_LIST_ADD_ITEM_SUCCESS,
    payload: items
});

export const addNominaItemError = (error) => ({
    type: NOMINAS_LIST_ADD_ITEM_ERROR,
    payload: error
});

export const selectedNominaItemsChange = (selectedItems) => ({
    type: NOMINAS_LIST_SELECTED_ITEMS_CHANGE,
    payload: selectedItems
});



export const changeLocale = (locale) => {
    localStorage.setItem('currentLanguage', locale);
    return (
        {
            type: CHANGE_LOCALE,
            payload: locale
        }
    )
}

