import React from 'react'

const data = [
    {
        siglas: "SD",
        significado:"Salarío diarío",
        variantes:" ",
        formulas: "Sueldo Bruto m/ 30 dias del mes = Salarío Diarío"
    },
    {
        siglas: "SDP",
        significado:"Salarío diarío promedio",
        variantes:" ",
        formulas: "Sueldo Bruto diarío promedio de los últimos 30 dias"
    },
    {
        siglas: "SD",
        significado:"Salarío diarío",
        variantes:" ",
        formulas: "Sueldo Bruto m/ 30 dias del mes = Salarío Diarío"
    },
    {
        siglas: "PV",
        significado:"Prima vacacional",
        variantes:<span>Salario diarío <br></br> Salario diarío promedio </span>,
        formulas:<span>Salarío diarío * Días de vacaciones * 0.25 (% marcado por la ley) = PV correspondiente"<br></br> S. diarío prom. * Dias de vacaciones * 0.25 (% marcado por ley) = PV correspondiente</span>
    },
    {
        siglas: "Aguinaldo",
        significado:"",
        variantes:<span>Salario fijo <br></br> Salario variable <br></br> Proporcional</span>,
        formulas:<span>Salarío diarío X 15 dias minimos oficiales = Percepción Monetaria de Aguinaldo <br></br> Sueldo Diatio Prom. X 15 dias mínimo oficiales = Percepcion Monetaria de Aguinaldo <br></br> (15 dias mínimo oficiales /365 dias)*(Dias laborados reales)*SD ó SDP = Aguinaldo</span>
    },
    {
        siglas: "FI",
        significado:"Factor de integración",
        variantes:" ",
        formulas:<span>(365 dias del año + dias de aguinaldo (15,20,25 +o 30) + PV) / 365 dias del año = FI </span>
    },
    {
        siglas: "SBC",
        significado:"Salario Base de cotizacíon",
        variantes:" ",
        formulas:<span>Sueldo mensual / 30 dias de mes = Saario Diario de cotización</span>
    },
    {
        siglas: "SDI",
        significado:"Salario Diario Integrado",
        variantes:" ",
        formulas:<span>Salario Diario de Cotización X Factor de integración = SDI</span>
    },
    {
        siglas: "ISR",
        significado:"Impuesto sobre la renta",
        variantes:" ",
        formulas:<span>Falta</span>
    },
    {
        siglas: "UMAS ",
        significado:"Unidad de medida x actulizacion",
        variantes:" ",
        formulas:<span>8449 se actualiza en febrero de cada año</span>
    },
   
  ]

export default data;