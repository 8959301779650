import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import conceptosListSagas from './conceptosList/saga'
import getGruposNominaListSagas from './GruposNominaList/saga'
import empleadosListSagas from './EmpleadoList/saga';
import variablesListSagas from './variablesList/saga';


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    conceptosListSagas(),
    getGruposNominaListSagas(),
    empleadosListSagas(),
    variablesListSagas()
  ]);
}
