import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import conceptosListApp from './conceptosList/reducers';
import gruposNominaListApp from './GruposNominaList/reducers';
import empleadosListApp from './EmpleadoList/reducers';
import variablesListApp from './variablesList/reducers';


const reducers = combineReducers({
  menu,
  settings,
  authUser,
  conceptosListApp,
  gruposNominaListApp,
  empleadosListApp,
  variablesListApp,
});

export default reducers;