import "./assets/css/vendor/bootstrap.min.css";
import "./assets/css/vendor/bootstrap.rtl.only.min.css";
import "react-circular-progressbar/dist/styles.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-table/react-table.css";

import { isMultiColorActive, defaultColor,themeColorStorageKey,isDarkSwitchActive } from "./constants/defaultValues";
const color =
  (isMultiColorActive||isDarkSwitchActive ) && localStorage.getItem(themeColorStorageKey)
    ? localStorage.getItem(themeColorStorageKey)
    : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

String.prototype.toThousandsFormat = function(decimales = 2) {
        if(!this)
            return this;
        var auxValue = this.split('.');
        var auxIntValue = auxValue[0].replace(/,/g, '');
        var auxDecimalValue = auxValue.length > 1? '.' + auxValue[1]:'';

        //Dejando solo 2 decimales
        if(auxValue[1]){
          if(auxValue[1].length > decimales)
            auxDecimalValue = auxDecimalValue.substring(0,decimales + 1);
          else if(auxValue[1].length == 1)
            auxDecimalValue = '.' + auxValue[1] + '0';
        } else 
          auxDecimalValue = '.00';
      
        if(auxIntValue.length > 0){
            var auxDividerValue = auxIntValue.length/3;
            var i;
            for (i = 1; i < Math.abs(auxDividerValue); i++) {
                var auxDividerPosition = 3 * i + (i - 1);
                auxIntValue = auxIntValue.substr(0, auxIntValue.length - auxDividerPosition) 
                + "," + 
                auxIntValue.substr(auxIntValue.length - auxDividerPosition);
            }
            return auxIntValue + auxDecimalValue;
        } else
            return auxIntValue + auxDecimalValue;
};

String.prototype.fromThousandsFormat = function() {
  if(!this)
      return this;

  return this.replace(/,/g, '');
}

let render = () => {
  import('./assets/css/sass/themes/gogo.' + color + '.scss').then(x => {
     require('./AppRenderer');
  });
};
render();