import {
    GRUPOS_NOMINA_LIST_GET_LIST,
    GRUPOS_NOMINA_LIST_GET_LIST_SUCCESS,
    GRUPOS_NOMINA_LIST_GET_LIST_ERROR,
    GRUPOS_NOMINA_LIST_GET_LIST_WITH_FILTER,
    GRUPOS_NOMINA_LIST_GET_LIST_WITH_ORDER,
    GRUPOS_NOMINA_LIST_GET_LIST_SEARCH,
    GRUPOS_NOMINA_LIST_ADD_ITEM,
    GRUPOS_NOMINA_LIST_ADD_ITEM_SUCCESS,
    GRUPOS_NOMINA_LIST_ADD_ITEM_ERROR,
    GRUPOS_NOMINA_LIST_SELECTED_ITEMS_CHANGE
} from '../actions';

export const getGruposNominaList = () => ({
    type: GRUPOS_NOMINA_LIST_GET_LIST
})

export const getGruposNominaListSuccess = (items) => ({
    type:  GRUPOS_NOMINA_LIST_GET_LIST_SUCCESS,
    payload: items
});

export const getGruposNominaListError = (error) => ({
    type: GRUPOS_NOMINA_LIST_GET_LIST_ERROR,
    payload: error
});

export const getGruposNominaListWithFilter = (column, value) => ({
    type: GRUPOS_NOMINA_LIST_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

export const getGruposNominaListWithOrder = (column) => ({
    type: GRUPOS_NOMINA_LIST_GET_LIST_WITH_ORDER,
    payload: column
});

export const getGruposNominaListSearch = (keyword) => ({
    type: GRUPOS_NOMINA_LIST_GET_LIST_SEARCH,
    payload: keyword
});

export const addGruposNominaItem = (item) => ({
    type: GRUPOS_NOMINA_LIST_ADD_ITEM,
    payload: item
});

export const addGruposNominaItemSuccess = (items) => ({
    type: GRUPOS_NOMINA_LIST_ADD_ITEM_SUCCESS,
    payload: items
});

export const addGruposNominaItemError = (error) => ({
    type: GRUPOS_NOMINA_LIST_ADD_ITEM_ERROR,
    payload: error
});

export const selectedGruposNominaItemsChange = (selectedItems) => ({
    type: GRUPOS_NOMINA_LIST_SELECTED_ITEMS_CHANGE,
    payload: selectedItems
});