import {
    CONCEPTOS_LIST_GET_LIST,
    CONCEPTOS_LIST_GET_LIST_SUCCESS,
    CONCEPTOS_LIST_GET_LIST_ERROR,
    CONCEPTOS_LIST_GET_LIST_WITH_FILTER,
    CONCEPTOS_LIST_GET_LIST_WITH_ORDER,
    CONCEPTOS_LIST_GET_LIST_SEARCH,
    CONCEPTOS_LIST_ADD_ITEM,
    CONCEPTOS_LIST_ADD_ITEM_SUCCESS,
    CONCEPTOS_LIST_ADD_ITEM_ERROR,
    CONCEPTOS_LIST_SELECTED_ITEMS_CHANGE
} from '../actions';

export const getConceptosList = () => ({
    type: CONCEPTOS_LIST_GET_LIST
})

export const getConceptosListSuccess = (items) => ({
    type:  CONCEPTOS_LIST_GET_LIST_SUCCESS,
    payload: items
});

export const getConceptosListError = (error) => ({
    type: CONCEPTOS_LIST_GET_LIST_ERROR,
    payload: error
});

export const getConceptosListWithFilter = (column, value) => ({
    type: CONCEPTOS_LIST_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

export const getConceptosListWithOrder = (column) => ({
    type: CONCEPTOS_LIST_GET_LIST_WITH_ORDER,
    payload: column
});

export const getConceptosListSearch = (keyword) => ({
    type: CONCEPTOS_LIST_GET_LIST_SEARCH,
    payload: keyword
});

export const addConceptosItem = (item) => ({
    type: CONCEPTOS_LIST_ADD_ITEM,
    payload: item
});

export const addConceptosItemSuccess = (items) => ({
    type: CONCEPTOS_LIST_ADD_ITEM_SUCCESS,
    payload: items
});

export const addConceptosItemError = (error) => ({
    type: CONCEPTOS_LIST_ADD_ITEM_ERROR,
    payload: error
});

export const selectedConceptosItemsChange = (selectedItems) => ({
    type: CONCEPTOS_LIST_SELECTED_ITEMS_CHANGE,
    payload: selectedItems
});