import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getDateWithFormat } from "../../helpers/Utils";
import axios from 'axios';


import {
    CONCEPTOS_LIST_GET_LIST,
    CONCEPTOS_LIST_ADD_ITEM
  } from "../actions";

  import {
    getConceptosListSuccess,
    getConceptosListError,
    addConceptosItemSuccess,
    addConceptosItemError
  } from "./actions";

  import ConceptosListData from "../../data/conceptos.list.json";

  const getConceptoListRequest = async () => {
    return await new Promise((success, fail) => {
      axios.post(`/api/Concepto/obtenerConceptosLista`, {idOrganizacion: 284 })
          .then(result => {
                  success(result.data.data);
          })
          .catch((error) => { fail(error) });
  })
  .then(response => response)
  .catch(error => error);
  };

  const addConceptoItemRequest = async item => {
    let items = ConceptosListData.data;
    item.id = items.length + 1;
    item.createDate = getDateWithFormat();
    items.splice(0, 0, item);
    return await new Promise((success, fail) => {
      setTimeout(() => {
        success(items);
      }, 1000);
    })
      .then(response => response)
      .catch(error => error);
  };

  function* getConceptoListItems() {
    try {
      const response = yield call(getConceptoListRequest);
      yield put(getConceptosListSuccess(response));
    } catch (error) {
      yield put(getConceptosListError(error));
    }
  }

  function* addConceptoItem({ payload }) {
    try {
      const response = yield call(addConceptoItemRequest, payload);
      yield put(addConceptosItemSuccess(response));
    } catch (error) {
      yield put(addConceptosItemError(error));
    }
  }
  
  export function* watchGetList() {
    yield takeEvery(CONCEPTOS_LIST_GET_LIST, getConceptoListItems);
  }
  export function* wathcAddItem() {
    yield takeEvery(CONCEPTOS_LIST_ADD_ITEM, addConceptoItem);
  }
  
  export default function* rootSaga() {
    yield all([fork(watchGetList), fork(wathcAddItem)]);
  }
  