/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";

//NOMINAS START
export const NOMINAS_LIST_GET_LIST = "NOMINAS_LIST_GET_LIST";
export const NOMINAS_LIST_GET_LIST_SUCCESS = "NOMINAS_LIST_GET_LIST_SUCCESS";
export const NOMINAS_LIST_GET_LIST_ERROR = "NOMINAS_LIST_GET_LIST_ERROR";
export const NOMINAS_LIST_GET_LIST_WITH_FILTER =
  "NOMINAS_LIST_GET_LIST_WITH_FILTER";
export const NOMINAS_LIST_GET_LIST_WITH_ORDER =
  "NOMINAS_LIST_GET_LIST_WITH_ORDER";
export const NOMINAS_LIST_GET_LIST_SEARCH = "NOMINAS_LIST_GET_LIST_SEARCH";
export const NOMINAS_LIST_ADD_ITEM = "NOMINAS_LIST_ADD_ITEM";
export const NOMINAS_LIST_ADD_ITEM_SUCCESS = "NOMINAS_LIST_ADD_ITEM_SUCCESS";
export const NOMINAS_LIST_ADD_ITEM_ERROR = "NOMINAS_LIST_ADD_ITEM_ERROR";
export const NOMINAS_LIST_SELECTED_ITEMS_CHANGE =
  "NOMINAS_LIST_SELECTED_ITEMS_CHANGE";

  /* SURVEY DETAIL APP*/
export const SURVEY_GET_DETAILS = "SURVEY_GET_DETAILS";
export const SURVEY_GET_DETAILS_SUCCESS = "SURVEY_GET_DETAILS_SUCCESS";
export const SURVEY_GET_DETAILS_ERROR = "SURVEY_GET_DETAILS_ERROR";
export const SURVEY_DELETE_QUESTION = "SURVEY_DELETE_QUESTION";
export const SURVEY_SAVE = "SURVEY_SAVE";

/* CONCEPTOS LIST APP*/
export const CONCEPTOS_LIST_GET_LIST = "CONCEPTOS_LIST_GET_LIST";
export const CONCEPTOS_LIST_GET_LIST_SUCCESS = "CONCEPTOS_LIST_GET_LIST_SUCCESS";
export const CONCEPTOS_LIST_GET_LIST_ERROR = "CONCEPTOS_LIST_GET_LIST_ERROR";

/* CONCEPTOS DETAIL APP*/
export const CONCEPTOS_GET_DETAILS = "CONCEPTOS_GET_DETAILS";
export const CONCEPTOS_GET_DETAILS_SUCCESS = "CONCEPTOS_GET_DETAILS_SUCCESS";
export const CONCEPTOS_GET_DETAILS_ERROR = "CONCEPTOS_GET_DETAILS_ERROR";
export const CONCEPTOS_DELETE_QUESTION = "CONCEPTOS_DELETE_QUESTION";
export const CONCEPTOS_SAVE = "CONCEPTOS_SAVE";

  /* GRUPOS_NOMINA LIST APP*/
  export const GRUPOS_NOMINA_LIST_GET_LIST = "GRUPOS_NOMINA_LIST_GET_LIST";
  export const GRUPOS_NOMINA_LIST_GET_LIST_SUCCESS = "GRUPOS_NOMINA_LIST_GET_LIST_SUCCESS";
  export const GRUPOS_NOMINA_LIST_GET_LIST_ERROR = "GRUPOS_NOMINA_LIST_GET_LIST_ERROR";
  export const GRUPOS_NOMINA_LIST_GET_LIST_WITH_FILTER =
    "GRUPOS_NOMINA_LIST_GET_LIST_WITH_FILTER";
  export const GRUPOS_NOMINA_LIST_GET_LIST_WITH_ORDER =
    "GRUPOS_NOMINA_LIST_GET_LIST_WITH_ORDER";
  export const GRUPOS_NOMINA_LIST_GET_LIST_SEARCH = "GRUPOS_NOMINA_LIST_GET_LIST_SEARCH";
  export const GRUPOS_NOMINA_LIST_ADD_ITEM = "GRUPOS_NOMINA_LIST_ADD_ITEM";
  export const GRUPOS_NOMINA_LIST_ADD_ITEM_SUCCESS = "GRUPOS_NOMINA_LIST_ADD_ITEM_SUCCESS";
  export const GRUPOS_NOMINA_LIST_ADD_ITEM_ERROR = "GRUPOS_NOMINA_LIST_ADD_ITEM_ERROR";
  export const GRUPOS_NOMINA_LIST_SELECTED_ITEMS_CHANGE =
    "GRUPOS_NOMINA_LIST_SELECTED_ITEMS_CHANGE";

      /* GRUPOS NOMINA DETAIL APP*/
export const GRUPOS_NOMINA_GET_DETAILS = "GRUPOS_NOMINA_GET_DETAILS";
export const GRUPOS_NOMINA_GET_DETAILS_SUCCESS = "GRUPOS_NOMINA_GET_DETAILS_SUCCESS";
export const GRUPOS_NOMINA_GET_DETAILS_ERROR = "GRUPOS_NOMINA_GET_DETAILS_ERROR";
export const GRUPOS_NOMINA_DELETE_QUESTION = "GRUPOS_NOMINA_DELETE_QUESTION";
export const GRUPOS_NOMINA_SAVE = "GRUPOS_NOMINA_SAVE";

/* GRUPOS NOMINAS-EMPLEADO */
export const GRUPOS_NOMINA_LIST_GET_GRUPOS = "GRUPOS_NOMINA_LIST_GET_GRUPOS";
export const GRUPOS_NOMINA_LIST_GET_GRUPOS_SUCCESS = "GRUPOS_NOMINA_LIST_GET_GRUPOS_SUCCESS";
export const GRUPOS_NOMINA_LIST_GET_GRUPOS_ERROR = "GRUPOS_NOMINA_LIST_GET_GRUPOS_ERROR";

/* VARIABLES LIST */
export const VARIABLES_LIST_GET_ITEMS = "VARIABLES_LIST_GET_ITEMS";
export const VARIABLES_LIST_GET_SUCCESS = "VARIABLES_LIST_GET_SUCCESS";
export const VARIABLES_LIST_GET_ERROR = "VARIABLES_LIST_GET_ERROR";


/* EMPLEADO LIST APP*/
  export const EMPLEADO_LIST_GET_LIST = "EMPLEADO_LIST_GET_LIST";
  export const EMPLEADO_LIST_GET_LIST_SUCCESS = "EMPLEADO_LIST_GET_LIST_SUCCESS";
  export const EMPLEADO_LIST_GET_LIST_ERROR = "EMPLEADO_LIST_GET_LIST_ERROR";
  export const EMPLEADO_LIST_GET_LIST_WITH_FILTER =
    "EMPLEADO_LIST_GET_LIST_WITH_FILTER";
  export const EMPLEADO_LIST_GET_LIST_WITH_ORDER =
    "EMPLEADO_LIST_GET_LIST_WITH_ORDER";
  export const EMPLEADO_LIST_GET_LIST_SEARCH = "EMPLEADO_LIST_GET_LIST_SEARCH";
  export const EMPLEADO_LIST_ADD_ITEM = "EMPLEADO_LIST_ADD_ITEM";
  export const EMPLEADO_LIST_ADD_ITEM_SUCCESS = "EMPLEADO_LIST_ADD_ITEM_SUCCESS";
  export const EMPLEADO_LIST_ADD_ITEM_ERROR = "EMPLEADO_LIST_ADD_ITEM_ERROR";
  export const EMPLEADO_LIST_SELECTED_ITEMS_CHANGE =
    "EMPLEADO_LIST_SELECTED_ITEMS_CHANGE";
  export const EMPLEADO_LIST_GET_EMPLEADO = "EMPLEADO_LIST_GET_EMPLEADO";
  export const EMPLEADO_LIST_GET_EMPLEADO_SUCCESS = "EMPLEADO_LIST_GET_EMPLEADO_SUCCESS";
  export const EMPLEADO_LIST_GET_EMPLEADO_ERROR = "EMPLEADO_LIST_GET_EMPLEADO_ERROR";

  export const EMPLEADO_LIST_GET_TIPOS_CONTRATOS = "EMPLEADO_LIST_GET_TIPOS_CONTRATOS";
  export const EMPLEADO_LIST_GET_TIPOS_CONTRATOS_SUCCESS = "EMPLEADO_LIST_GET_TIPOS_CONTRATOS_SUCCESS";
export const EMPLEADO_LIST_GET_TIPOS_CONTRATOS_ERROR = "EMPLEADO_LIST_GET_TIPOS_CONTRATOS_ERROR";

export const EMPLEADO_LIST_GET_PERIODICIDAD_PAGO = "EMPLEADO_LIST_GET_PERIODICIDAD_PAGO";
export const EMPLEADO_LIST_GET_PERIODICIDAD_PAGO_SUCCESS = "EMPLEADO_LIST_GET_PERIODICIDAD_PAGO_SUCCESS";
export const EMPLEADO_LIST_GET_PERIODICIDAD_PAGO_ERROR = "EMPLEADO_LIST_GET_PERIODICIDAD_PAGO_ERROR";

export const EMPLEADO_LIST_GET_METODO_PAGO = "EMPLEADO_LIST_GET_METODO_PAGO";
export const EMPLEADO_LIST_GET_METODO_PAGO_SUCCESS = "EMPLEADO_LIST_GET_METODO_PAGO_SUCCESS";
export const EMPLEADO_LIST_GET_METODO_PAGO_ERROR = "EMPLEADO_LIST_GET_METODO_PAGO_ERROR";

export const EMPLEADO_LIST_GET_REGISTRO_PATRONA = "EMPLEADO_LIST_GET_REGISTRO_PATRONA";
export const EMPLEADO_LIST_GET_REGISTRO_PATRONA_SUCCESS = "EMPLEADO_LIST_GET_REGISTRO_PATRONA_SUCCESS";
export const EMPLEADO_LIST_GET_REGISTO_PATRONA_ERROR = "EMPLEADO_LIST_GET_REGISTO_PATRONA_ERROR";

export const EMPLEADO_LIST_GET_TIPO_CREDITO = "EMPELADO_LIST_GET_TIPO_CREDITO";
export const EMPLEADO_LIST_GET_TIPO_CREDITO_SUCCESS = "EMPLEADO_LIST_GET_TIPO_CREDITO_SUCCESS";
export const EMPLEADO_LIST_GET_TIPO_CREDITO_ERROR ="EMPLEADO_LIST_GET_TIPO_CREDITO_ERROR";

export const EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO = "EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO";
export const EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO_SUCCESS = "EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO_SUCCESS";
export const EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO_ERROR = "EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO_ERROR";

export const EMPLEADO_LIST_GET_TIPOS_EMPLEADO = "EMPLEADO_LIST_GET_TIPOS_EMPLEADO";
export const EMPLEADO_LIST_GET_TIPOS_EMPLEADO_SUCCESS = "EMPLEADO_LIST_GET_TIPOS_EMPLEADO_SUCCESS";
export const EMPLEADO_LIST_GET_TIPOS_EMPLEADO_ERROR = "EMPLEADO_LIST_GET_TIPOS_EMPLEADO_ERROR";

export const EMPLEADO_LIST_GET_TIPOS_REGIMEN = "EMPLEADO_LIST_GET_TIPOS_REGIMEN";
export const EMPLEADO_LIST_GET_TIPOS_REGIMEN_SUCCESS = "EMPLEADO_LIST_GET_TIPOS_REGIMEN_SUCCESS";
export const EMPLEADO_LIST_GET_TIPOS_REGIMEN_ERROR = "EMPLEADO_LIST_GET_TIPOS_REGIMEN_ERROR";

export const EMPLEADO_LIST_GET_DEPARTAMENTOS = "EMPLEADO_LIST_GET_DEPARTAMENTOS";
export const EMPLEADO_LIST_GET_DEPARTAMENTOS_SUCCESS = "EMPLEADO_LIST_GET_DEPARTAMENTOS_SUCCESS";
export const EMPLEADO_LIST_GET_DEPARTAMENTOS_ERROR = "EMPLEADO_LIST_GET_DEPARTAMENTOS_ERROR";

export const EMPLEADO_LIST_GET_PUESTOS = "EMPLEADO_LIST_GET_PUESTOS";
export const EMPLEADO_LIST_GET_PUESTOS_SUCCESS = "EMPLEADO_LIST_GET_PUESTOS_SUCCESS";
export const EMPLEADO_LIST_GET_PUESTOS_ERROR = "EMPLEADO_LIST_GET_PUESTOS_ERROR";

export const EMPLEADO_LIST_GET_SUCURSALES = "EMPLEADO_LIST_GET_SUCURSALES";
export const EMPLEADO_LIST_GET_SUCURSALES_SUCCESS = "EMPLEADO_LIST_GET_SUCURSALES_SUCCESS";
export const EMPLEADO_LIST_GET_SUCURSALES_ERROR = "EMPLEADO_LIST_GET_SUCURSALES_ERROR";

export const EMPLEADO_LIST_GET_RIESGOS_TRABAJO = "EMPLEADO_LIST_GET_RIESGOS_TRABAJO";
export const EMPLEADO_LIST_GET_RIESGOS_TRABAJO_SUCCESS = "EMPLEADO_LIST_GET_RIESGOS_TRABAJO_SUCCESS";
export const EMPLEADO_LIST_GET_RIESGOS_TRABAJO_ERROR = "EMPLEADO_LIST_GET_RIESGOS_TRABAJO_ERROR";

export const EMPLEADO_LIST_GET_JORNADAS_TRABAJO = "EMPLEADO_LIST_GET_JORNADAS_TRABAJO";
export const EMPLEADO_LIST_GET_JORNADAS_TRABAJO_SUCCESS = "EMPLEADO_LIST_GET_JORNADAS_TRABAJO_SUCCESS";
export const EMPLEADO_LIST_GET_JORNADAS_TRABAJO_ERROR = "EMPLEADO_LIST_GET_JORNADAS_TRABAJO_ERROR";

export const EMPLEADO_LIST_GET_GUARDAR = "EMPLEADO_LIST_GET_GUARDAR";
export const EMPLEADO_LIST_GET_GUARDAR_SUCCESS = "EMPLEADO_LIST_GET_GUARDAR_SUCCESS";
export const EMPLEADO_LIST_GET_GUARDAR_ERROR = "EMPLEADO_LIST_GET_GUARDAR_ERROR";

export const EMPLEADO_LIST_GET_ESTADO_CIVIL = "EMPLEADO_LIST_GET_ESTADO_CIVIL";
export const EMPLEADO_LIST_GET_ESTADO_CIVIL_SUCCESS = "EMPLEADO_LIST_GET_ESTADO_CIVIL_SUCCESS";
export const EMPLEADO_LIST_GET_ESTADO_CIVIL_ERROR = "EMPLEADO_LIST_GET_ESTADO_CIVIL_ERROR";

export const EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS = "EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS";
export const EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS_SUCCESS = "EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS_SUCCESS";
export const EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS_ERROR = "EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS_ERROR";

export const EMPLEADO_LIST_GET_CENTROS_COSTOS = "EMPLEADO_LIST_GET_CENTROS_COSTOS";
export const EMPLEADO_LIST_GET_CENTROS_COSTOS_SUCCESS = "EMPLEADO_LIST_GET_CENTROS_COSTOS_SUCCESS";
export const EMPLEADO_LIST_GET_CENTROS_COSTOS_ERROR = "EMPLEADO_LIST_GET_CENTROS_COSTOS_ERROR";

export const EMPLEADO_LIST_VALIDA_CODIGO = "EMPLEADO_LIST_VALIDA_CODIGO";
export const EMPLEADO_LIST_VALIDA_CODIGO_SUCCESS = "EMPLEADO_LIST_VALIDA_CODIGO_SUCCESS";
export const EMPLEADO_LIST_VALIDA_CODIGO_ERROR = "EMPLEADO_LIST_VALIDA_CODIGO_ERROR";



export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./surveyList/actions";
export * from "./conceptosList/actions";
export * from "./EmpleadoList/actions";

