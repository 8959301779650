import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getDateWithFormat, localURL } from "../../helpers/Utils";
import axios from 'axios';

import {
    VARIABLES_LIST_GET_ITEMS,
    VARIABLES_LIST_GET_SUCCESS,
    VARIABLES_LIST_GET_ERROR
}from "../actions";

import {
    getVariablesListSuccess,
    getVariablesListError,
} from './actions'

import data from "../../data/variables.js";

const getVariablesListRequest = async () => {
    return await new Promise((success, fail) => {
      setTimeout(() => {
        success(data.data);
      }, 1000);
    })
      .then(response => response)
      .catch(error => error);
  };

  function* getVariablesListItems() {
    try {
      const response = yield call(getVariablesListRequest);
      yield put(getVariablesListSuccess(response));
    } catch (error) {
      yield put(getVariablesListError(error));
    }
  }

  export function* watchGetList() {
    yield takeEvery(VARIABLES_LIST_GET_ITEMS, getVariablesListItems);
  }

  export default function* rootSaga() {
    yield all([fork(watchGetList)]);
  }
  