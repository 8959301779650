import { NotificationManager } from "../../components/common/react-notifications";
import classnames from "classnames";
import {
    EMPLEADO_LIST_GET_LIST,
    EMPLEADO_LIST_GET_LIST_SUCCESS,
    EMPLEADO_LIST_GET_LIST_ERROR,
    EMPLEADO_LIST_GET_LIST_WITH_FILTER,
    EMPLEADO_LIST_GET_LIST_WITH_ORDER,
    EMPLEADO_LIST_GET_LIST_SEARCH,
    EMPLEADO_LIST_ADD_ITEM,
    EMPLEADO_LIST_ADD_ITEM_SUCCESS,
    EMPLEADO_LIST_ADD_ITEM_ERROR,
    EMPLEADO_LIST_SELECTED_ITEMS_CHANGE,
    EMPLEADO_LIST_GET_EMPLEADO,
    EMPLEADO_LIST_GET_EMPLEADO_SUCCESS,
    EMPLEADO_LIST_GET_EMPLEADO_ERROR,
    EMPLEADO_LIST_GET_TIPOS_CONTRATOS,
    EMPLEADO_LIST_GET_TIPOS_CONTRATOS_SUCCESS,
    EMPLEADO_LIST_GET_TIPOS_CONTRATOS_ERROR,
    EMPLEADO_LIST_GET_PERIODICIDAD_PAGO,
    EMPLEADO_LIST_GET_PERIODICIDAD_PAGO_SUCCESS,
    EMPLEADO_LIST_GET_PERIODICIDAD_PAGO_ERROR,
    EMPLEADO_LIST_GET_METODO_PAGO,
    EMPLEADO_LIST_GET_METODO_PAGO_SUCCESS,
    EMPLEADO_LIST_GET_METODO_PAGO_ERROR,
    EMPLEADO_LIST_GET_TIPOS_EMPLEADO,
    EMPLEADO_LIST_GET_TIPOS_EMPLEADO_SUCCESS,
    EMPLEADO_LIST_GET_TIPOS_EMPLEADO_ERROR,
    EMPLEADO_LIST_GET_TIPOS_REGIMEN,
    EMPLEADO_LIST_GET_TIPOS_REGIMEN_SUCCESS,
    EMPLEADO_LIST_GET_TIPOS_REGIMEN_ERROR,
    EMPLEADO_LIST_GET_DEPARTAMENTOS,
    EMPLEADO_LIST_GET_DEPARTAMENTOS_SUCCESS,
    EMPLEADO_LIST_GET_DEPARTAMENTOS_ERROR,
    EMPLEADO_LIST_GET_PUESTOS,
    EMPLEADO_LIST_GET_PUESTOS_SUCCESS,
    EMPLEADO_LIST_GET_PUESTOS_ERROR,
    EMPLEADO_LIST_GET_SUCURSALES,
    EMPLEADO_LIST_GET_SUCURSALES_SUCCESS,
    EMPLEADO_LIST_GET_SUCURSALES_ERROR,
    EMPLEADO_LIST_GET_RIESGOS_TRABAJO,
    EMPLEADO_LIST_GET_RIESGOS_TRABAJO_SUCCESS,
    EMPLEADO_LIST_GET_RIESGOS_TRABAJO_ERROR,
    EMPLEADO_LIST_GET_JORNADAS_TRABAJO,
    EMPLEADO_LIST_GET_JORNADAS_TRABAJO_SUCCESS,
    EMPLEADO_LIST_GET_JORNADAS_TRABAJO_ERROR,
    EMPLEADO_LIST_GET_GUARDAR,
    EMPLEADO_LIST_GET_GUARDAR_SUCCESS,
    EMPLEADO_LIST_GET_GUARDAR_ERROR,
    EMPLEADO_LIST_GET_REGISTRO_PATRONA,
    EMPLEADO_LIST_GET_REGISTRO_PATRONA_SUCCESS,
    EMPLEADO_LIST_GET_REGISTO_PATRONA_ERROR,
    EMPLEADO_LIST_GET_TIPO_CREDITO,
    EMPLEADO_LIST_GET_TIPO_CREDITO_SUCCESS,
    EMPLEADO_LIST_GET_TIPO_CREDITO_ERROR,
    EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO,
    EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO_SUCCESS,
    EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO_ERROR,
    EMPLEADO_LIST_GET_ESTADO_CIVIL,
    EMPLEADO_LIST_GET_ESTADO_CIVIL_SUCCESS,
    EMPLEADO_LIST_GET_ESTADO_CIVIL_ERROR,
    EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS,
    EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS_SUCCESS,
    EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS_ERROR,
    EMPLEADO_LIST_GET_CENTROS_COSTOS,
    EMPLEADO_LIST_GET_CENTROS_COSTOS_SUCCESS,
    EMPLEADO_LIST_GET_CENTROS_COSTOS_ERROR,
    GRUPOS_NOMINA_LIST_GET_GRUPOS,
    GRUPOS_NOMINA_LIST_GET_GRUPOS_SUCCESS,
    GRUPOS_NOMINA_LIST_GET_GRUPOS_ERROR,
    EMPLEADO_LIST_VALIDA_CODIGO,
    EMPLEADO_LIST_VALIDA_CODIGO_SUCCESS,
    EMPLEADO_LIST_VALIDA_CODIGO_ERROR

} from '../actions';

const cmbEmptyValue = { value: null, label: "" };

const INIT_STATE = {
	allEmpleadoItems: [],
    empleadosItems: [],
    tiposContrato: [],
    periodicidades: [],
    metodosDePago: [],
    tiposEmpleado: [],
    tiposDeRegimen: [],
    departamentos: [],
    puestos: [],
    sucursales: [],
    riesgos: [],
    jornadasDeTrabajo: [],
    estadosCivil: [],
    areasGeograficas: [],
    centrosCostos: [],
    grupoNominas:[],
    empleado:{
        idEmpleado:null,
        numEmpleado:null,
        activo:1,
        nombre:"",
        imagen:"",
        fechaAlta:null,
        numContrato:null,
        idTipoContrato:null,
        descripcionContrato:null,
        tipoContrato: cmbEmptyValue,
        idDepartamento:null,
        descripcionDepartamento:null,
        departamento: cmbEmptyValue,
        idPuesto:null,
        descripcionPuesto:null,
        puesto: cmbEmptyValue,
        periodicidad: null,
        idMetodoPago:null,
        descripcionMetodoPago:null,
        metodoPago: cmbEmptyValue,
        idTipoEmpleado:null,
        descripcionTipoEmpleado:null,
        tipoEmpleado: cmbEmptyValue,
        idTipoRegimen:null,
        descripcionTipoRegimen:null,
        tipoRegimen: cmbEmptyValue,
        idGrupoNomina: null,
        grupoNominaDescripcion: null,
        grupoNomina: cmbEmptyValue,
        tipoNomina:null,
        idCentroCosto: null,
        centroCostoDescripcion: null,
        centroCosto:cmbEmptyValue,
        idSucursal:null,
        descripcionSucursal:null,
        sucursal: cmbEmptyValue,
        idRiesgoTrabajo:null,
        descripcionRiesgoTrabajo:null,
        riesgoTrabajo: cmbEmptyValue,
        idTurnoTrabajo:null,
        descripcionTurnoTrabajo:null,
        tipoJornada: cmbEmptyValue,
        idAreaGeografica: null,
        descripcionAreaGeografica:null,
        areaGeografica: cmbEmptyValue,
        fechaNacimiento:null,
        idEstadoCivil:null,
        descripcionEstadoCivil:null,
        estadoCivil: cmbEmptyValue,
        idSexo:null,
        descripcionSexo:null,
        sexo: cmbEmptyValue,
        dependientesEconomicos:null,
        telefono:null,
        domicilio:"",
        localidad:null,
        estado: null,
        municipio:null,
        idCodigoPostal:null,
        descripcionCodigoPostal:null,
        codigoPostal:null,
        correo:""
    },
    error:"",
	errors: {},
	filter: null,
	searchKeyword: '',
	orderColumn: null,
	loading: false,
	orderColumns: [
		{ column: "empleado", label: "Empleado" },
		{ column: "tipoContrato", label: "Tipo de Contrato" },
		{ column: "tipoRegimen", label: "Tipo de Regimen" },
        { column: "RFC", label: "RFC" },
        { column: "CURP", label: "CURP" },
        { column: "entidad", label: "Entidad" },
        { column: "dir", label: "Direccion" },
        { column: "sucursal", label: "Sucursal"}
	],
	selectedItems: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case EMPLEADO_LIST_GET_LIST:
            return { ...state, loading: false };
        case EMPLEADO_LIST_GET_LIST_SUCCESS:
            return { ...state, loading: true, allEmpleadoItems: action.payload, empleadosItems: action.payload };
        case EMPLEADO_LIST_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload };
        case EMPLEADO_LIST_GET_LIST_WITH_FILTER:
			if (action.payload.column === '' || action.payload.value === '') {
				return { ...state, loading: true, empleadosItems: state.allEmpleadoItems, filter: null };
			} else {
				const filteredItems = state.allEmpleadoItems.filter((item) =>
					item[action.payload.column] === action.payload.value);
				return {
					...state, loading: true, empleadosItems: filteredItems, filter: {
						column: action.payload.column,
						value: action.payload.value
					}
				}
            }
            case EMPLEADO_LIST_GET_LIST_WITH_ORDER:
                if (action.payload === '') {
                    return { ...state, loading: true, empleadosItems: state.empleadosItems, orderColumn: null };
                } else {
                    const sortedItems = state.empleadosItems.sort((a, b) => {
                        if (
                            a[action.payload] <
                            b[action.payload]
                        )
                            return -1;
                        else if (
                            a[action.payload] >
                            b[action.payload]
                        )
                            return 1;
                        return 0;
                    })
                    return { ...state, loading: true, empleadosItems: sortedItems, orderColumn: state.orderColumns.find(x => x.column === action.payload) }
                }
            case EMPLEADO_LIST_GET_LIST_SEARCH:
                if (action.payload === '') {
                    return { ...state, empleadosItems: state.allEmpleadoItems };
                } else {
                    const keyword = action.payload.toLowerCase();
                    const searchItems = state.allEmpleadoItems.filter((item) =>
                        item.nombre.toLowerCase().indexOf(keyword) > -1 || item.puesto.toLowerCase().indexOf(keyword) > -1  || item.tipoContrato.toLowerCase().indexOf(keyword) > -1 || item.tipoRegimen.toLowerCase().indexOf(keyword) > -1 || item.RFC.toLowerCase().indexOf(keyword) > -1|| item.CURP.toLowerCase().indexOf(keyword) > -1|| item.entidad.toLowerCase().indexOf(keyword) > -1 || item.sucursal.toLowerCase().indexOf(keyword) > -1 ); //agregar mas || item.status.toLowerCase().indexOf(keyword) > -1 || item.category.toLowerCase().indexOf(keyword) > -1 || item.label.toLowerCase().indexOf(keyword) > -1
                    return { ...state, loading: true, empleadosItems: searchItems, searchKeyword: action.payload }
                }
            case EMPLEADO_LIST_ADD_ITEM:
                return { ...state, loading: false };    
            
            case EMPLEADO_LIST_ADD_ITEM_SUCCESS:
                return { ...state, loading: true, allEmpleadoItems: action.payload, empleadosItems: action.payload };
            
            case EMPLEADO_LIST_ADD_ITEM_ERROR:
                return { ...state, loading: true, error: action.payload };
            
            case EMPLEADO_LIST_SELECTED_ITEMS_CHANGE:
                    return { ...state, loading: true, selectedItems: action.payload};
            
            case EMPLEADO_LIST_GET_EMPLEADO:
                return { loading: true, empleado: action.payload};

            case EMPLEADO_LIST_GET_EMPLEADO_SUCCESS:
                return { ...state, loading: false, empleado: action.payload };

            case EMPLEADO_LIST_GET_EMPLEADO_ERROR:
            return { ...state, loading: false, error: action.payload };

        case EMPLEADO_LIST_GET_METODO_PAGO:
            return { ...state, loading: true };

        case EMPLEADO_LIST_GET_METODO_PAGO_SUCCESS:
            if (!action.payload.error) {
                var datos = action.payload.data.map((val) => {
                    return { value: val.id, label: val.clave + " - " + val.descripcion, objeto: val };
                });
                return { ...state, loading: false, metodosDePago: datos }
            }
            else {
                return { ...state, loading: false, error: action.payload.errorMessage };
            }

        case EMPLEADO_LIST_GET_METODO_PAGO_ERROR:
            return {...state,loading:false,error:action.payload}

        case EMPLEADO_LIST_GET_PERIODICIDAD_PAGO:
            return { ...state, loading: true };

        case EMPLEADO_LIST_GET_PERIODICIDAD_PAGO_SUCCESS:
            if (!action.payload.error) {
                var datos = action.payload.data.map((val) => {
                    return { value: val.id, label: val.clave + " - " + val.descripcion, objeto: val };
                });
                return { ...state, loading: false, periodicidades: datos }
            }
            else {
                return { ...state, loading: false }
            }
        case EMPLEADO_LIST_GET_PERIODICIDAD_PAGO_ERROR:
            return { ...state, loading: false, error: action.payload }

         case EMPLEADO_LIST_GET_TIPOS_CONTRATOS:
              return {...state,loading:true};

        case EMPLEADO_LIST_GET_TIPOS_CONTRATOS_SUCCESS:
            if (!action.payload.error) {
                var tipos = action.payload.data.map((val) => {
                    return { value: val.id, label: val.clave + " - " + val.descripcion, objeto: val };
                });
                return { ...state, loading: false, tiposContrato: tipos }
            }
            else {
                return { ...state, loading: false }
            }
            case EMPLEADO_LIST_GET_TIPOS_CONTRATOS_ERROR:
              return { ...state, loading: false, error: action.payload }

        case EMPLEADO_LIST_GET_TIPOS_EMPLEADO:
            return { ...state, loading: true };

        case EMPLEADO_LIST_GET_TIPOS_EMPLEADO_SUCCESS:
            if (!action.payload.error) {
                var tipos = action.payload.data.map((val) => {
                    return { value: val.id, label: val.clave + " - " + val.descripcion, objeto: val };
                });
                return { ...state, loading: false, tiposEmpleado: tipos }
            }
            else 
                return { ...state, loading: false }

        case EMPLEADO_LIST_GET_TIPOS_EMPLEADO_ERROR:
            return { ...state, loading: false, error: action.payload }

        case EMPLEADO_LIST_GET_TIPOS_REGIMEN:
            return { ...state, loading: true }

        case EMPLEADO_LIST_GET_TIPOS_REGIMEN_SUCCESS:
            if (!action.payload.error) {
                var tipos = action.payload.data.map((val) => {
                    return { value: val.id, label: val.clave + " - " + val.descripcion, objeto: val };
                });
                return { ...state, loading: false, tiposDeRegimen: tipos }
            }
            else
                return { ...state, loading: false }

        case EMPLEADO_LIST_GET_TIPOS_REGIMEN_ERROR:
            return { ...state, loading: false, error: action.payload }

        case EMPLEADO_LIST_GET_DEPARTAMENTOS:
            return { ...state, loading: false };

        case EMPLEADO_LIST_GET_DEPARTAMENTOS_SUCCESS:
            if (!action.payload.error) {
                var departamentos = action.payload.data.map((val) => {
                    return { value: val.id, label: val.descripcion, objeto: val };
                });
                return { ...state, loading: false, departamentos: departamentos };
            }
            else
                return { ...state, loading: false };

        case EMPLEADO_LIST_GET_DEPARTAMENTOS_ERROR:
            return { ...state, loading: false, error: action.payload }
  
        case EMPLEADO_LIST_GET_PUESTOS:
            return { ...state, loading: false };

        case EMPLEADO_LIST_GET_PUESTOS_SUCCESS:
            if (!action.payload.error) {
                var puestos = action.payload.data.map((val) => {
                    return { value: val.id, label: val.descripcion, objeto: val };
                });
                return { ...state, loading: false, puestos: puestos };
            }
            else
                return { ...state, loading: false };

        case EMPLEADO_LIST_GET_PUESTOS_ERROR:
            return { ...state, loading: false, error: action.payload }

            
        case EMPLEADO_LIST_GET_SUCURSALES:
            return { ...state, loading: false };

        case EMPLEADO_LIST_GET_SUCURSALES_SUCCESS:
            if (!action.payload.error) {
                var sucursales = action.payload.data.map((val) => {
                    return { value: val.id, label: val.descripcion, objeto: val };
                });
                return { ...state, loading: false, sucursales: sucursales };
            }
            else
                return { ...state, loading: false };

        case EMPLEADO_LIST_GET_SUCURSALES_ERROR:
            return { ...state, loading: false, error: action.payload }

        case EMPLEADO_LIST_GET_RIESGOS_TRABAJO:
            return { ...state, loading: false };

        case EMPLEADO_LIST_GET_RIESGOS_TRABAJO_SUCCESS:
            if (!action.payload.error) {
                var riesgos = action.payload.data.map((val) => {
                    return { value: val.id, label: val.clave+' '+val.descripcion, objeto: val };
                });
                return { ...state, loading: false, riesgos: riesgos };
            }
            else
                return { ...state, loading: false };

        case EMPLEADO_LIST_GET_RIESGOS_TRABAJO_ERROR:
            return { ...state, loading: false, error: action.payload }

        case EMPLEADO_LIST_GET_JORNADAS_TRABAJO:
            return { ...state, loading: true };

        case EMPLEADO_LIST_GET_JORNADAS_TRABAJO_SUCCESS:
            if (!action.payload.error) {
                var data = action.payload.data.map((val) => {
                    return { value: val.id, label: val.clave + ' ' + val.descripcion, objeto: val };
                });
                return { ...state, loading: false, jornadasDeTrabajo: data };
            }
            else
                return { ...state, loading: false };

        case EMPLEADO_LIST_GET_JORNADAS_TRABAJO_ERROR:
            return { ...state, loading: false, error: action.payload }

        case EMPLEADO_LIST_GET_GUARDAR:
            return { ...state, loading: true,empleado:action.payload };
        case EMPLEADO_LIST_GET_GUARDAR_SUCCESS:
            console.log("success", action);
            return { ...state, loading: false };
        case EMPLEADO_LIST_GET_GUARDAR_ERROR:
            NotificationManager.error( action.payload,"Error",5000, () => { },null,'filled');
            return { ...state, loading: false,error:action.payload };
        

        case EMPLEADO_LIST_GET_REGISTRO_PATRONA:
                return { ...state, loading: false };
        
        case EMPLEADO_LIST_GET_REGISTRO_PATRONA_SUCCESS:
                if (!action.payload.error) {
                    var patrona = action.payload.data.map((val) => {
                        return { value: val.id, label: val.clave+' '+val.descripcion, objeto: val };
                    });
                    return { ...state, loading: false, patrona: patrona };
                }
                else
                    return { ...state, loading: false };

        case EMPLEADO_LIST_GET_REGISTO_PATRONA_ERROR:
                return { ...state, loading: false, error: action.payload }

        case EMPLEADO_LIST_GET_TIPO_CREDITO:
            return  { ...state, loading: true }

        case EMPLEADO_LIST_GET_TIPO_CREDITO_SUCCESS:
            return { ...state, loading: false }

        case EMPLEADO_LIST_GET_TIPO_CREDITO_ERROR:
            return { ...state, loading: false }

        case EMPLEADO_LIST_GET_ESTADO_CIVIL:
            return { ...state, loading: true };

        case EMPLEADO_LIST_GET_ESTADO_CIVIL_SUCCESS:
                var data = action.payload.data.map((val) => {
                    return { value: val.id, label: val.descripcion, objeto: val };
                });
            return { ...state, loading: false, estadosCivil: data };

        case EMPLEADO_LIST_GET_ESTADO_CIVIL_ERROR:
            //NotificationManager.error(action.payload, "Error", 5000, () => { }, null, 'filled');
            return { ...state, loading: false, error: action.payload }

        case EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS:
            return { ...state, loading: true };

        case EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS_SUCCESS:
            var data = action.payload.data.map((val) => {
                return { value: val.id, label: val.descripcion, objeto: val };
            });
            return { ...state, loading: false, areasGeograficas: data };

        case EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS_ERROR:
            NotificationManager.error(action.payload, "Error", 5000, () => { }, null, 'filled');
            return { ...state, loading: false, error: action.payload }

        /* #region centros costos*/
        case EMPLEADO_LIST_GET_CENTROS_COSTOS:
            return { ...state, loading: true };

        case EMPLEADO_LIST_GET_CENTROS_COSTOS_SUCCESS:
            var data = action.payload.data.map((val) => {
                return { value: val.id, label: val.clave+' - '+val.descripcion, objeto: val };
            });
            return { ...state, loading: false, centrosCostos: data };

        case EMPLEADO_LIST_GET_CENTROS_COSTOS_ERROR:
            NotificationManager.error(action.payload, "Error", 5000, () => { }, null, 'filled');
            return { ...state, loading: false, error: action.payload }
        /* #endregion*/

        /*#region grupo de nomina*/
        case GRUPOS_NOMINA_LIST_GET_GRUPOS:
            return { ...state, loading: true };

        case GRUPOS_NOMINA_LIST_GET_GRUPOS_SUCCESS:
            var data = action.payload.data.map((val) => {
                return { value: val.id, label: val.nombre, objeto: val };
            });
            return { ...state, loading: false, grupoNominas: data };

        case GRUPOS_NOMINA_LIST_GET_GRUPOS_ERROR:
            //NotificationManager.error(action.payload, "Error", 5000, () => { }, null, 'filled');
            return { ...state, loading: false, error: action.payload }
        /*#endregion*/

        case EMPLEADO_LIST_VALIDA_CODIGO:
            var empleado = state.empleado;
            empleado.codigoPostal = action.payload;
            return { ...state, loading: true,empleado:empleado };
        case EMPLEADO_LIST_VALIDA_CODIGO_SUCCESS:
            return { ...state, loading: false };
        case EMPLEADO_LIST_VALIDA_CODIGO_ERROR:
            NotificationManager.error(action.payload, "Error", 5000, () => { }, null, 'filled');
            return { ...state, loading: false };
            
      default: return { ...state };
    }
}