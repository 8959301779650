import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getDateWithFormat } from "../../helpers/Utils";
import axios from 'axios';

import {
    GRUPOS_NOMINA_LIST_GET_LIST,
    GRUPOS_NOMINA_LIST_ADD_ITEM
  } from "../actions";

  import {
    getGruposNominaListSuccess,
    getGruposNominaListError,
    addGruposNominaItemSuccess,
    addGruposNominaItemError
  } from "./actions";

  import GruposNominaListData from "../../data/grupoNomina.list.json"

  const getGruposNominaListRequest = async () => {
    return await new Promise((success, fail) => {
      setTimeout(() => {
        axios.post(`/api/GrupoNomina/mostrarGruposNomina`,{idOrganizacion:null})
        .then(result => {
                success(result.data);
                console.log(result.data);
        })
        .catch((error) => { fail(error) });
      }, 1000);
    })
      .then(response => response)
      .catch(error => error);
  };
  
  function* getGruposNominaListItems() {
    try {
        const response = yield call(getGruposNominaListRequest);
        if(!response.error)
            yield put(getGruposNominaListSuccess(response));
        else
            yield put(getGruposNominaListError(response.errorMessage));
    } catch (error) {
      yield put(getGruposNominaListError(error));
    }
  }
  
  const addGruposNominaItemRequest = async item => {
    let items = GruposNominaListData.data;
    item.id = items.length + 1;
    item.createDate = getDateWithFormat();
    items.splice(0, 0, item);
    return await new Promise((success, fail) => {
      setTimeout(() => {
        success(items);
      }, 1000);
    })
      .then(response => response)
      .catch(error => error);
  };
  
  function* addGruposNominaItem({ payload }) {
    try {
      const response = yield call(addGruposNominaItemRequest, payload);
      yield put(addGruposNominaItemSuccess(response));
    } catch (error) {
      yield put(addGruposNominaItemError(error));
    }
  }
  
  export function* watchGetList() {
    yield takeEvery(GRUPOS_NOMINA_LIST_GET_LIST, getGruposNominaListItems);
  }
  
  export function* wathcAddItem() {
    yield takeEvery(GRUPOS_NOMINA_LIST_ADD_ITEM, addGruposNominaItem);
  }
  
  export default function* rootSaga() {
    yield all([fork(watchGetList), fork(wathcAddItem)]);
  }
