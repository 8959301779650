import {
    CONCEPTOS_LIST_GET_LIST,
    CONCEPTOS_LIST_GET_LIST_SUCCESS,
    CONCEPTOS_LIST_GET_LIST_ERROR,
    CONCEPTOS_LIST_GET_LIST_WITH_FILTER,
    CONCEPTOS_LIST_GET_LIST_WITH_ORDER,
    CONCEPTOS_LIST_GET_LIST_SEARCH,
    CONCEPTOS_LIST_ADD_ITEM,
    CONCEPTOS_LIST_ADD_ITEM_SUCCESS,
    CONCEPTOS_LIST_ADD_ITEM_ERROR,
    CONCEPTOS_LIST_SELECTED_ITEMS_CHANGE,
} from '../actions';

const INIT_STATE = {
	allConceptosItems: null,
    conceptosItems: null,
    conceptoDetail: null,
	errors: {},
	filter: null,
	searchKeyword: '',
	orderColumn: null,
	loading: false,
	operaciones: [
        { label: "Percepcion", value: 0, key: 0 },
        { label: "Deduccion", value: 1, key: 1 },
        { label: "Otros Pagos", value: 2, key: 2 }
      ],
	orderColumns: [
		{ column: "folio", label: "Folio" },
		{ column: "descripcion", label: "Descripcion" },
		{ column: "operacion", label: "Operacion" },
		{ column: "tipoOperacion", label: "Tipo de Operacion" },
	],
    tiposDeOperaciones: [
        { label: "c_TipoPercepcion", value: 0, key: 0},
        { label: "c_TipoDeduccion", value: 1, key: 0},
        { label: "c_TipoOtroPago", value: 2, key: 0},
        { label: "c_tipoHorasExtra", value: 3, key: 0, horasextra: true },
        { label: "c_TipoPercepcion", value: 4, key: 1},
        { label: "c_TipoDeduccion", value: 5, key: 1},
        { label: "c_TipoOtroPago", value: 6, key: 1},
        { label: "c_TipoPercepcion", value: 7, key: 2},
        { label: "c_TipoDeduccion", value: 8, key: 2},
        { label: "c_TipoOtroPago", value: 9, key: 2}
      ],
    tiposHorasExtra: [
        {label:"Dobles", value: 0},
        {label:"Triples", value: 1},
        {label:"Quadruples", value: 2},
    ],
    
	selectedItems: []
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case CONCEPTOS_LIST_GET_LIST:
            return { ...state, loading: false };
        case CONCEPTOS_LIST_GET_LIST_SUCCESS:
            return { ...state, loading: true, allConceptosItems: action.payload, conceptosItems: action.payload };
        case CONCEPTOS_LIST_GET_LIST_ERROR:
            return { ...state, loading: true, error: action.payload };
        default: return { ...state };
    }
}
    