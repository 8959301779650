import {
    EMPLEADO_LIST_GET_LIST,
    EMPLEADO_LIST_GET_LIST_SUCCESS,
    EMPLEADO_LIST_GET_LIST_ERROR,
    EMPLEADO_LIST_GET_LIST_WITH_FILTER,
    EMPLEADO_LIST_GET_LIST_WITH_ORDER,
    EMPLEADO_LIST_GET_LIST_SEARCH,
    EMPLEADO_LIST_ADD_ITEM,
    EMPLEADO_LIST_ADD_ITEM_SUCCESS,
    EMPLEADO_LIST_ADD_ITEM_ERROR,
    EMPLEADO_LIST_SELECTED_ITEMS_CHANGE,
    EMPLEADO_LIST_GET_EMPLEADO,
    EMPLEADO_LIST_GET_EMPLEADO_SUCCESS,
    EMPLEADO_LIST_GET_EMPLEADO_ERROR,
    EMPLEADO_LIST_GET_TIPOS_CONTRATOS,
    EMPLEADO_LIST_GET_TIPOS_CONTRATOS_SUCCESS,
    EMPLEADO_LIST_GET_TIPOS_CONTRATOS_ERROR,
    EMPLEADO_LIST_GET_PERIODICIDAD_PAGO,
    EMPLEADO_LIST_GET_PERIODICIDAD_PAGO_SUCCESS,
    EMPLEADO_LIST_GET_PERIODICIDAD_PAGO_ERROR,
    EMPLEADO_LIST_GET_METODO_PAGO,
    EMPLEADO_LIST_GET_METODO_PAGO_SUCCESS,
    EMPLEADO_LIST_GET_METODO_PAGO_ERROR,
    EMPLEADO_LIST_GET_TIPOS_EMPLEADO,
    EMPLEADO_LIST_GET_TIPOS_EMPLEADO_SUCCESS,
    EMPLEADO_LIST_GET_TIPOS_EMPLEADO_ERROR,
    EMPLEADO_LIST_GET_TIPOS_REGIMEN,
    EMPLEADO_LIST_GET_TIPOS_REGIMEN_SUCCESS,
    EMPLEADO_LIST_GET_TIPOS_REGIMEN_ERROR,
    EMPLEADO_LIST_GET_DEPARTAMENTOS,
    EMPLEADO_LIST_GET_DEPARTAMENTOS_SUCCESS,
    EMPLEADO_LIST_GET_DEPARTAMENTOS_ERROR,
    EMPLEADO_LIST_GET_PUESTOS,
    EMPLEADO_LIST_GET_PUESTOS_SUCCESS,
    EMPLEADO_LIST_GET_PUESTOS_ERROR,
    EMPLEADO_LIST_GET_SUCURSALES,
    EMPLEADO_LIST_GET_SUCURSALES_SUCCESS,
    EMPLEADO_LIST_GET_SUCURSALES_ERROR,
    EMPLEADO_LIST_GET_RIESGOS_TRABAJO,
    EMPLEADO_LIST_GET_RIESGOS_TRABAJO_SUCCESS,
    EMPLEADO_LIST_GET_RIESGOS_TRABAJO_ERROR,
    EMPLEADO_LIST_GET_JORNADAS_TRABAJO,
    EMPLEADO_LIST_GET_JORNADAS_TRABAJO_SUCCESS,
    EMPLEADO_LIST_GET_JORNADAS_TRABAJO_ERROR,
    EMPLEADO_LIST_GET_REGISTRO_PATRONA,
    EMPLEADO_LIST_GET_REGISTRO_PATRONA_SUCCESS,
    EMPLEADO_LIST_GET_REGISTO_PATRONA_ERROR,
    EMPLEADO_LIST_GET_GUARDAR,
    EMPLEADO_LIST_GET_GUARDAR_SUCCESS,
    EMPLEADO_LIST_GET_GUARDAR_ERROR,
    EMPLEADO_LIST_GET_TIPO_CREDITO,
    EMPLEADO_LIST_GET_TIPO_CREDITO_SUCCESS,
    EMPLEADO_LIST_GET_TIPO_CREDITO_ERROR,
    EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO,
    EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO_SUCCESS,
    EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO_ERROR,
    EMPLEADO_LIST_GET_ESTADO_CIVIL,
    EMPLEADO_LIST_GET_ESTADO_CIVIL_SUCCESS,
    EMPLEADO_LIST_GET_ESTADO_CIVIL_ERROR,
    EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS,
    EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS_SUCCESS,
    EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS_ERROR,
    EMPLEADO_LIST_GET_CENTROS_COSTOS,
    EMPLEADO_LIST_GET_CENTROS_COSTOS_SUCCESS,
    EMPLEADO_LIST_GET_CENTROS_COSTOS_ERROR,
    GRUPOS_NOMINA_LIST_GET_GRUPOS,
    GRUPOS_NOMINA_LIST_GET_GRUPOS_SUCCESS,
    GRUPOS_NOMINA_LIST_GET_GRUPOS_ERROR,
    EMPLEADO_LIST_VALIDA_CODIGO,
    EMPLEADO_LIST_VALIDA_CODIGO_SUCCESS,
    EMPLEADO_LIST_VALIDA_CODIGO_ERROR
} from '../actions';

export const getEmpleado = (idEmpleado) => ({
    type: EMPLEADO_LIST_GET_EMPLEADO,
    payload: idEmpleado
})

export const getEmpleadoSuccess = (empleado) => ({
    type: EMPLEADO_LIST_GET_EMPLEADO_SUCCESS,
    payload: empleado
})

export const getEmpleadoError = (error) => ({
    type: EMPLEADO_LIST_GET_EMPLEADO_ERROR,
    payload: error
})

/* #region GUARDAR EMPLEADO*/
export const saveEmpleado = (empleado) => ({
    type: EMPLEADO_LIST_GET_GUARDAR,
    payload: empleado
});

export const saveEmpleadoSuccess = () => ({
    type: EMPLEADO_LIST_GET_GUARDAR_SUCCESS
});

export const saveEmpleadoError = (error) => ({
    type: EMPLEADO_LIST_GET_GUARDAR_ERROR,
    payload: error
});

/* #endregion */

/* #region validaCodigo*/

export const validaCodigo = (codigo) => ({
    type: EMPLEADO_LIST_VALIDA_CODIGO,
    payload: codigo
});

export const validaCodigoSuccess = (data) => ({
    type: EMPLEADO_LIST_VALIDA_CODIGO_SUCCESS,
    payload: data
});

export const validaCodigoError = (error) => ({
    type: EMPLEADO_LIST_VALIDA_CODIGO_ERROR,
    payload: error
});
/* #endregion */

/* #region OBTENER GRUPOS NOMINAS*/

export const getGruposNominas = () => ({
    type: GRUPOS_NOMINA_LIST_GET_GRUPOS
})

export const getGruposNominasSuccess = (data) => ({
    type: GRUPOS_NOMINA_LIST_GET_GRUPOS_SUCCESS,
    payload: data
})

export const getGruposNominasError = (error) => ({
    type: GRUPOS_NOMINA_LIST_GET_GRUPOS_ERROR,
    payload: error
})
/* #endregion */

/* #region OBTENER AREAS GEOGRAFICAS*/

export const getAreasGeograficas = () => ({
    type: EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS
})

export const getAreasGeograficasSuccess = (data) => ({
    type: EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS_SUCCESS,
    payload: data
})

export const getAreasGeograficasError = (error) => ({
    type: EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS_ERROR,
    payload: error
})
/* #endregion */

/* #region OBTENER CENTROS COSTOS*/

export const getCentrosCostos = () => ({
    type: EMPLEADO_LIST_GET_CENTROS_COSTOS
})

export const getCentrosCostosSuccess = (data) => ({
    type: EMPLEADO_LIST_GET_CENTROS_COSTOS_SUCCESS,
    payload: data
})

export const getCentrosCostosError = (error) => ({
    type: EMPLEADO_LIST_GET_CENTROS_COSTOS_ERROR,
    payload: error
})
/* #endregion */

/* #region OBTENER ESTADO CIVIL*/

    export const getEstadoCivil = () => ({
        type: EMPLEADO_LIST_GET_ESTADO_CIVIL
    })

    export const getEstadoCivilSuccess = (data) => ({
        type: EMPLEADO_LIST_GET_ESTADO_CIVIL_SUCCESS,
        payload: data
    })

    export const getEstadoCivilError = (error) => ({
        type: EMPLEADO_LIST_GET_ESTADO_CIVIL_ERROR,
        payload: error
    })
/* #endregion */


/*#region Bancos pago Bancario */
export const getBancoPagoBancario = (idBancoPago) => ({
    type: EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO,
    payload: idBancoPago
})

export const getBancoPagoBancarioSuccess = (bancoPago) => ({
    type: EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO_SUCCESS,
    payload: bancoPago
})

export const getBancoPagoBancarioError = (error) => ({
    type: EMPLEADO_LIST_GET_BANCO_PAGO_BANCARIO_ERROR,
    payload: error
})
/* #endregion */

/* #region JORNADA DE TRABAJO */
export const getJornadasTrabajo = () => ({
    type: EMPLEADO_LIST_GET_JORNADAS_TRABAJO
});

export const getJornadasTrabajoSuccess = (jornadas) => ({
    type: EMPLEADO_LIST_GET_JORNADAS_TRABAJO_SUCCESS,
    payload: jornadas
});

export const getJornadasTrabajoError = (error) => ({
    type: EMPLEADO_LIST_GET_JORNADAS_TRABAJO_ERROR,
    payload: error
});
/* #endregion */
/* #region TipoCredito */ 
export const getTipoCredito = (idTipoCredito) => ({
    type: EMPLEADO_LIST_GET_TIPO_CREDITO,
    payload: idTipoCredito
})

export const getTipoCreditoSuccess = (tipoCredito) => ({
    type: EMPLEADO_LIST_GET_TIPO_CREDITO_SUCCESS,
    payload: tipoCredito
})

export const getTipoCreditoError = (error) =>({
    type: EMPLEADO_LIST_GET_TIPO_CREDITO_ERROR,
    payload: error
})
/* #endregion */

/* #region RegistroPatrona*/
export const getRegistroPatrona = (parametro) => ({
    type: EMPLEADO_LIST_GET_REGISTRO_PATRONA,
    payload: parametro
}) 

export const getRegistroPatronaSuccess = (patrona) => ({
    type: EMPLEADO_LIST_GET_REGISTRO_PATRONA_SUCCESS,
    payload: patrona
})

export const getRegistroPatronaError = (error) =>({
    type: EMPLEADO_LIST_GET_REGISTO_PATRONA_ERROR,
    payload: error
})
/*#endregion*/

/* #region RIESGOS DE TRABAJO*/
export const getRiesgosTrabajo = () => ({
    type: EMPLEADO_LIST_GET_RIESGOS_TRABAJO
});

export const getRiesgosTrabajoSuccess = (riesgos) => ({
    type: EMPLEADO_LIST_GET_RIESGOS_TRABAJO_SUCCESS,
    payload: riesgos
});

export const getRiesgosTrabajoError = (error) => ({
    type: EMPLEADO_LIST_GET_RIESGOS_TRABAJO_ERROR,
    payload: error
});
/* #endregion */

/* #region SUCUESALES*/
export const getSucursales = () => ({
    type: EMPLEADO_LIST_GET_SUCURSALES
});

export const getSucursalesSuccess = (sucursales) => ({
    type: EMPLEADO_LIST_GET_SUCURSALES_SUCCESS,
    payload: sucursales
});

export const getSucursalesError = (error) => ({
    type: EMPLEADO_LIST_GET_SUCURSALES_ERROR,
    payload: error
});
/* #endregion */

/* #region PUESTOS*/
export const getPuestos = () => ({
    type: EMPLEADO_LIST_GET_PUESTOS
});

export const getPuestosSuccess = (puestos) => ({
    type: EMPLEADO_LIST_GET_PUESTOS_SUCCESS,
    payload: puestos
});

export const getPuestosError = (error) => ({
    type: EMPLEADO_LIST_GET_PUESTOS_ERROR,
    payload: error
});
/* #endregion */

/* #region DEPARTAMENTOS*/
export const getDepartamentos = () => ({
    type: EMPLEADO_LIST_GET_DEPARTAMENTOS
});

export const getDepartamentosSuccess = (departamentos) => ({
    type: EMPLEADO_LIST_GET_DEPARTAMENTOS_SUCCESS,
    payload: departamentos
});

export const getDepartamentosError = (error) => ({
    type: EMPLEADO_LIST_GET_DEPARTAMENTOS_ERROR,
    payload: error
});
/* #endregion */


/* #region TIPOS DE REGIMEN*/
export const getTiposDeRegimen = () => ({
    type: EMPLEADO_LIST_GET_TIPOS_REGIMEN
});

export const getTiposDeRegimenSuccess = (tipos) => ({
    type: EMPLEADO_LIST_GET_TIPOS_REGIMEN_SUCCESS,
    payload: tipos
});

export const getTiposDeRegimenError = (error) => ({
    type: EMPLEADO_LIST_GET_TIPOS_REGIMEN_ERROR,
    payload: error
});
/* #endregion */

/* #region TIPOS DE EMPLEADO */
export const getTiposDeEmpleado = () => ({
    type: EMPLEADO_LIST_GET_TIPOS_EMPLEADO
});

export const getTiposDeEmpleadoSuccess = (tipos) => ({
    type: EMPLEADO_LIST_GET_TIPOS_EMPLEADO_SUCCESS,
    payload: tipos
});

export const getTiposDeEmpleadoError = (error) => ({
    type: EMPLEADO_LIST_GET_TIPOS_EMPLEADO_ERROR,
    payload: error
});
/* #endregion */

/* #region PERIODICIDADES DE PAGO */
export const getPeriodicidadDePago = () => ({
    type: EMPLEADO_LIST_GET_PERIODICIDAD_PAGO
});

export const getPeriodicidadDePagoSuccess = (periodicidades) => ({
    type: EMPLEADO_LIST_GET_PERIODICIDAD_PAGO_SUCCESS,
    payload: periodicidades
});

export const getPeriodicidadDePagoError = (error) => ({
    type: EMPLEADO_LIST_GET_PERIODICIDAD_PAGO_ERROR,
    payload: error
});

/* #endregion */

/* #region METODOS DE PAGO */
export const getMetodosDePago = () => ({
    type: EMPLEADO_LIST_GET_METODO_PAGO
});
export const getMetodosDePagoSuccess = (metodosDePago) => ({
    type: EMPLEADO_LIST_GET_METODO_PAGO_SUCCESS,
    payload: metodosDePago
});
export const getMetodosDePagoError = (error) => ({
    type: EMPLEADO_LIST_GET_METODO_PAGO_ERROR,
    payload:error
});
/* #endregion */

/* #region  TIPOS DE CONTRATO */
export const getTiposContrato = () => ({
    type: EMPLEADO_LIST_GET_TIPOS_CONTRATOS
})

export const getTiposContratoSuccess = (tipos) => ({
    type: EMPLEADO_LIST_GET_TIPOS_CONTRATOS_SUCCESS,
    payload: tipos
})

export const getTiposContratoError = (error) => ({
    type: EMPLEADO_LIST_GET_TIPOS_CONTRATOS_ERROR,
    payload: error
})
/* #endregion */


export const getEmpleadoList = () => ({
    type: EMPLEADO_LIST_GET_LIST
})

export const getEmpleadoListSuccess = (items) => ({
    type: EMPLEADO_LIST_GET_LIST_SUCCESS,
    payload: items
});

export const getEmpleadoListError = (error) => ({
    type: EMPLEADO_LIST_GET_LIST_ERROR,
    payload: error
});

export const getEmpleadoListWithFilter = (column, value) => ({
    type: EMPLEADO_LIST_GET_LIST_WITH_FILTER,
    payload: { column, value }
});

export const getEmpleadoListWithOrder = (column) => ({
    type: EMPLEADO_LIST_GET_LIST_WITH_ORDER,
    payload: column
});

export const getEmpleadoListSearch = (keyword) => ({
    type: EMPLEADO_LIST_GET_LIST_SEARCH,
    payload: keyword
});

export const addEmpleadoItem = (item) => ({
    type: EMPLEADO_LIST_ADD_ITEM,
    payload: item
});

export const addEmpleadoItemSuccess = (items) => ({
    type: EMPLEADO_LIST_ADD_ITEM_SUCCESS,
    payload: items
});

export const addEmpleadoItemError = (error) => ({
    type: EMPLEADO_LIST_ADD_ITEM_ERROR,
    payload: error
});

export const selectedEmpleadoItemsChange = (selectedItems) => ({
    type: EMPLEADO_LIST_SELECTED_ITEMS_CHANGE,
    payload: selectedItems
});