import { NotificationManager } from "../../components/common/react-notifications";
import {
    VARIABLES_LIST_GET_ITEMS,
    VARIABLES_LIST_GET_SUCCESS,
    VARIABLES_LIST_GET_ERROR
} from '../actions';

const INIT_STATE = {
    allVariablesItems: [],
    variablesItems:[],
    errors: {},
    loading: false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case VARIABLES_LIST_GET_ITEMS:
            return { ...state, loading: false };
        case VARIABLES_LIST_GET_SUCCESS:
                console.log("success", action);
            return { ...state, loading: true, allVariablesItems: action.payload.data, variablesItems: action.payload.data };
        case VARIABLES_LIST_GET_ERROR:
                console.log("error", action);
            NotificationManager.error(action.payload, "Error", 5000, () => { }, null, 'filled');
            return { ...state, loading: true, error: action.payload };
        default: return { ...state };
    }
}