import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getDateWithFormat, localURL } from "../../helpers/Utils";
import axios from 'axios';

import {
    EMPLEADO_LIST_GET_LIST,
    EMPLEADO_LIST_ADD_ITEM,
    EMPLEADO_LIST_GET_EMPLEADO,
    EMPLEADO_LIST_GET_TIPOS_CONTRATOS,
    EMPLEADO_LIST_GET_PERIODICIDAD_PAGO,
    EMPLEADO_LIST_GET_METODO_PAGO,
    EMPLEADO_LIST_GET_TIPOS_EMPLEADO,
    EMPLEADO_LIST_GET_TIPOS_REGIMEN,
    EMPLEADO_LIST_GET_DEPARTAMENTOS,
    EMPLEADO_LIST_GET_PUESTOS,
    EMPLEADO_LIST_GET_SUCURSALES,
    EMPLEADO_LIST_GET_RIESGOS_TRABAJO,
    EMPLEADO_LIST_GET_JORNADAS_TRABAJO,
    EMPLEADO_LIST_GET_GUARDAR,
    EMPLEADO_LIST_GET_ESTADO_CIVIL,
    EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS,
    EMPLEADO_LIST_GET_CENTROS_COSTOS,
    GRUPOS_NOMINA_LIST_GET_GRUPOS,
    EMPLEADO_LIST_VALIDA_CODIGO
  } from "../actions";

  import {
    getEmpleadoListSuccess,
    getEmpleadoListError,
    addEmpleadoItemSuccess,
    addEmpleadoItemError,
    getEmpleadoSuccess,
    getEmpleadoError,
    getTiposContratoSuccess,
    getTiposContratoError,
    getPeriodicidadDePagoSuccess,
    getPeriodicidadDePagoError,
    getMetodosDePagoSuccess,
    getMetodosDePagoError,
    getTiposDeEmpleadoSuccess,
    getTiposDeEmpleadoError,
    getTiposDeRegimenSuccess,
    getTiposDeRegimenError,
    getDepartamentosSuccess,
    getDepartamentosError,
    getPuestosSuccess,
    getPuestosError,
    getSucursalesSuccess,
    getSucursalesError,
    getRiesgosTrabajoSuccess,
    getRiesgosTrabajoError,
    getJornadasTrabajoSuccess,
    getJornadasTrabajoError,
    saveEmpleadoSuccess,
    saveEmpleadoError,
    getEstadoCivilSuccess,
    getEstadoCivilError,
    getAreasGeograficasSuccess,
    getAreasGeograficasError,
    getCentrosCostosSuccess,
    getCentrosCostosError,
    getGruposNominasSuccess,
    getGruposNominasError,
    validaCodigoSuccess,
    validaCodigoError
  } from "./actions";


const getEmpleadoListRequest = async (empleado) => {
    return await new Promise((success, fail) => {
        axios.post(`/api/Empleado/obtenerEmpleados`, {idOrganizacion: 284 })
            .then(result => {
                    success(result.data.data);
            })
            .catch((error) => { fail(error) });
    })
    .then(response => response)
    .catch(error => error);
};

const getMetodosPagoRequest = async () => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.post(`/api/General/obtenerMetodosDePago`, {idMetodoPago:null})
                .then(result => {
                    if (!result.data.error)
                        success(result.data);
                    else fail(result.data.errorMessage);

                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};

  const getTiposContratosRequest = async () => {
    return await new Promise((success, fail) => {
      setTimeout(() => {
          axios.post(`/api/General/obtenerTiposContrato`,  {idTipoContrato: null })
              .then(result => {
                  console.log("getTiposContratosRequest", result.data);
                  if (!result.data.error)
                      success(result.data);
                  else fail(result.data.errorMessage);      
                 
          })
          .catch( (error) => { fail(error) });
      }, 1000);
    })
      .then(response => response)
      .catch(error => error);
};

const getPeriodicidadRequest = async () => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.post(`/api/General/obtenerPeriodicidades`, {idPeriodicidadPago: null})
                .then(result => {
                    if (!result.data.error)
                        success(result.data.data);
                    else fail(result.data.errorMessage);

                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};
const getTiposEmpleadoRequest = async () => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.post(`/api/General/obtenerTiposDeEmpleado`, {organizacionId:284, tipoEmpleadoId:null})
                .then(result => {
                    if (!result.data.error)
                        success(result.data);
                    else fail(result.data.errorMessage);

                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};
const getTiposRegimenRequest = async () => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.post(`/api/General/obtenerTiposDeRegimen`)
                .then(result => {
                    console.log("getTiposRegimenRequest", result.data);
                    if (!result.data.error)
                        success(result.data);
                    else fail(result.data.errorMessage);

                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};
const getDepartamentosRequest = async () => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.post(`/api/General/obtenerDepartamentos`, {idOrganizacion:284, idDepartamento:null})
                .then(result => {
                    if (!result.data.error)
                        success(result.data);
                    else fail(result.data.errorMessage);

                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};
const getPuestosRequest = async () => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.post(`/api/General/obtenerPuestos`, {idOrganizacion:284, idPuesto:null})
                .then(result => {
                    if (!result.data.error)
                        success(result.data);
                    else fail(result.data.errorMessage);

                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};

const getSucursalesRequest = async () => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.post(`/api/General/obtenerSucursales`, {organizacionId:284, sucursalId:null})
                .then(result => {
                    if (!result.data.error)
                        success(result.data);
                    else fail(result.data.errorMessage);

                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};
const getRiesgosRequest = async () => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.post(`/api/General/obtenerRiesgosTrabajo`)
                .then(result => {
                    if (!result.data.error)
                        success(result.data);
                    else fail(result.data.errorMessage);

                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};
const getJornadasRequest = async () => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.post(`/api/General/obtenerJornadasTrabajo`, {idTipoJornada:null})
                .then(result => {
                    if (!result.data.error)
                        success(result.data);
                    else fail(result.data.errorMessage);

                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};
const saveEmployeeRequest = async (empleado) => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.post(`/api/Empleado/guardarEmpleado`, {empleado: JSON.parse(JSON.stringify(empleado.payload)) })
                .then(result => {
                        success(result.data);
                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};

const getEstadosCivilesRequest = async () => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.post(`/api/General/obtenerEstadosCiviles`)
                .then(result => {
                        success(result.data);
                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};
const getAreasGeograficasRequest = async () => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.get(`/api/General/obtenerEstadosCiviles`)
                .then(result => {
                    success(result.data);
                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};
const getCentrosCostosRequest = async () => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.post(`/api/General/obtenerCentrosCostos`, {organizacionId:284, centroCostoId:null})
                .then(result => {
                    success(result.data);
                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};
const getGruposNominasRequest = async () => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.get(`/api/GrupoNomina/obtenerGruposNomina`, {organizacionId:285, grupoNominaid:null})
                .then(result => {
                    success(result.data);
                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};
const getCodigoPostalRequest = async (codigo) => {
    return await new Promise((success, fail) => {
        setTimeout(() => {
            axios.get(`/api/Empleado/validaCodigoPostal/${codigo}`)
                .then(result => {
                    success(result.data);
                })
                .catch((error) => { fail(error) });
        }, 1000);
    })
        .then(response => response)
        .catch(error => error);
};
  function* getEmpleadoItem(item){
    try {
      const response = yield call(getEmpleadoListRequest);
      yield put(getEmpleadoSuccess(response));
    } catch (error) {
      yield put(getEmpleadoError(error));
    }
}

  function* getTiposContrato(){
    try {
      const response = yield call(getTiposContratosRequest);
      yield put(getTiposContratoSuccess(response));
    } catch (error) {
      yield put(getTiposContratoError(error));
    }
}

function* getPeriodicidades() {
    try {
        const response = yield call(getPeriodicidadRequest);
        yield put(getPeriodicidadDePagoSuccess(response));
    } catch (error) {
        yield put(getPeriodicidadDePagoError(error));
    }
}

function* getMetodosDePago() {
    try {
        const response = yield call(getMetodosPagoRequest);
        yield put(getMetodosDePagoSuccess(response));
    } catch (error) {
        yield put(getMetodosDePagoError(error));
    }
}

function* getTiposEmpleado() {
    try {
        const response = yield call(getTiposEmpleadoRequest);
        yield put(getTiposDeEmpleadoSuccess(response));
    } catch (error) {
        yield put(getTiposDeEmpleadoError(error));
    }
}

function* getTiposRegimen() {
    try {
        const response = yield call(getTiposRegimenRequest);
        yield put(getTiposDeRegimenSuccess(response));
    } catch (error) {
        yield put(getTiposDeRegimenError(error));
    }
}

function* getDepartamentos() {
    try {
        const response = yield call(getDepartamentosRequest);
        yield put(getDepartamentosSuccess(response));
    } catch (error) {
        yield put(getDepartamentosError(error));
    }
}

function* getPuestos() {
    try {
        const response = yield call(getPuestosRequest);
        yield put(getPuestosSuccess(response));
    } catch (error) {
        yield put(getPuestosError(error));
    }
}  

function* getSucursales(){
    try {
        const response = yield call(getSucursalesRequest);
        yield put(getSucursalesSuccess(response));
    } catch (error) {
        yield put(getSucursalesError(error));
    }
}

function* getRiesgos() {
    try {
        const response = yield call(getRiesgosRequest);
        yield put(getRiesgosTrabajoSuccess(response));
    } catch (error) {
        yield put(getRiesgosTrabajoError(error));
    }
}

  function* getEmpleadoListItems() {
    try {
      const response = yield call(getEmpleadoListRequest);
      yield put(getEmpleadoListSuccess(response));
    } catch (error) {
      yield put(getEmpleadoListError(error));
    }
  }
  
  const addEmpleadoItemRequest = async item => {
      /*
    let items = EmpleadosListData.data;
    item.id = items.length + 1;
    item.createDate = getDateWithFormat();
    items.splice(0, 0, item);
    return await new Promise((success, fail) => {
      setTimeout(() => {
        success(items);
      }, 1000);
    })
      .then(response => response)
      .catch(error => error);
      */
  };
  
  function* addEmpleadoItem({ payload }) {
    try {
      const response = yield call(addEmpleadoItemRequest, payload);
      yield put(addEmpleadoItemSuccess(response));
    } catch (error) {
      yield put(addEmpleadoItemError(error));
    }
}

function* getJornadasTrabajo() {
    try {
        const response = yield call(getJornadasRequest);
        yield put(getJornadasTrabajoSuccess(response));
    } catch (error) {
        yield put(getJornadasTrabajoError(error));
    }
}

function* saveEmpleado(item) {
    try {
        const response = yield call(saveEmployeeRequest,item);
        if (!response.error)
            yield put(saveEmpleadoSuccess(response));
        else
            yield put(saveEmpleadoError(response.errorMessage));
    } catch (error) {
        console.log("error tield", error);
        yield put(saveEmpleadoError(error));
    }
}

function* getEstadosCiviles() {
    try {
        const response = yield call(getEstadosCivilesRequest);
        if (!response.error)
            yield put(getEstadoCivilSuccess(response));
        else
            yield put(getEstadoCivilError(response.errorMessage));
    } catch (error) {
        yield put(getEstadoCivilError(error));
    }
}
function* getCentrosCostos() {
    try {
        const response = yield call(getCentrosCostosRequest);
        if (!response.error)
            yield put(getCentrosCostosSuccess(response));
        else
            yield put(getCentrosCostosError(response.errorMessage));
    } catch (error) {
        yield put(getCentrosCostosError(error));
    }
}

function* getAreasGeograficas() {
    try {
        const response = yield call(getAreasGeograficasRequest);
        if (!response.error)
            yield put(getAreasGeograficasSuccess(response));
        else
            yield put(getAreasGeograficasError(response.errorMessage));
    } catch (error) {
        yield put(getAreasGeograficasError(error));
    }
}

function* validaCodigoPostal(codigo) {
    try {
        const response = yield call(getCodigoPostalRequest,codigo);
        if (!response.error)
            yield put(validaCodigoSuccess(response));
        else
            yield put(validaCodigoError(response.errorMessage));
    } catch (error) {
        yield put(validaCodigoError(error));
    }
}

function* getGruposNominas() {
    try {
        const response = yield call(getGruposNominasRequest);
        if (!response.error)
            yield put(getGruposNominasSuccess(response));
        else
            yield put(getGruposNominasError(response.errorMessage));
    } catch (error) {
        yield put(getGruposNominasError(error));
    }
}

  export function* watchGetEmpleado(){
    yield takeEvery(EMPLEADO_LIST_GET_EMPLEADO, getEmpleadoItem);
}

export function* watchGetPeriodicidadPago() {
    yield takeEvery(EMPLEADO_LIST_GET_PERIODICIDAD_PAGO, getPeriodicidades);
}

export function* watchGetMetodosDePago() {
    yield takeEvery(EMPLEADO_LIST_GET_METODO_PAGO, getMetodosDePago);
}

  export function* watchGetTiposContratos() {
    yield takeEvery(EMPLEADO_LIST_GET_TIPOS_CONTRATOS, getTiposContrato);
   }

export function* watchGetTiposEmpleado() {
    yield takeEvery(EMPLEADO_LIST_GET_TIPOS_EMPLEADO, getTiposEmpleado);
}

export function* watchGetTiposRegimen() {
    yield takeEvery(EMPLEADO_LIST_GET_TIPOS_REGIMEN, getTiposRegimen);
}

export function* watchGetDepartamentos() {
    yield takeEvery(EMPLEADO_LIST_GET_DEPARTAMENTOS, getDepartamentos);
}

export function* watchGetPuestos() {
    yield takeEvery(EMPLEADO_LIST_GET_PUESTOS, getPuestos);
}

export function* watchGetSucursales() {
    yield takeEvery(EMPLEADO_LIST_GET_SUCURSALES, getSucursales);
} 

export function* watchGetRiesgosTrabajo() {
   yield takeEvery(EMPLEADO_LIST_GET_RIESGOS_TRABAJO, getRiesgos);
}

  export function* watchGetList() {
    yield takeEvery(EMPLEADO_LIST_GET_LIST, getEmpleadoListItems);
  }
  
  export function* wathcAddItem() {
    yield takeEvery(EMPLEADO_LIST_ADD_ITEM, addEmpleadoItem);
}

export function* watchJornadasTrabajo() {
    yield takeEvery(EMPLEADO_LIST_GET_JORNADAS_TRABAJO, getJornadasTrabajo);
}

export function* watchSaveEmpleado() {
    yield takeEvery(EMPLEADO_LIST_GET_GUARDAR, saveEmpleado);
}

export function* watchGetEstadosCiviles() {
    yield takeEvery(EMPLEADO_LIST_GET_ESTADO_CIVIL, getEstadosCiviles);
}

export function* watchAreasGeograficas() {
    yield takeEvery(EMPLEADO_LIST_GET_AREAS_GEOGRAFICAS, getAreasGeograficas);
}

export function* watchCentrosCostos() {
    yield takeEvery(EMPLEADO_LIST_GET_CENTROS_COSTOS, getCentrosCostos);
}
export function* watchGruposNominas() {
    yield takeEvery(GRUPOS_NOMINA_LIST_GET_GRUPOS, getGruposNominas);
}
export function* watchValidadCodigoPostal() {
     yield takeEvery(EMPLEADO_LIST_VALIDA_CODIGO,validaCodigoPostal)
}

  export default function* rootSaga() {
      yield all([
          fork(watchGetList),
          fork(wathcAddItem),
          fork(watchGetEmpleado),
          fork(watchGetTiposContratos),
          fork(watchGetPeriodicidadPago),
          fork(watchGetMetodosDePago),
          fork(watchGetTiposEmpleado),
          fork(watchGetTiposRegimen),
          fork(watchGetDepartamentos),
          fork(watchGetPuestos),
          fork(watchGetSucursales),
          fork(watchGetRiesgosTrabajo),
          fork(watchJornadasTrabajo),
          fork(watchSaveEmpleado),
          fork(watchGetEstadosCiviles),
          fork(watchAreasGeograficas),
          fork(watchCentrosCostos),
          fork(watchGruposNominas),
          fork(watchValidadCodigoPostal)
      ]);
  }
