import {
    VARIABLES_LIST_GET_ITEMS,
    VARIABLES_LIST_GET_SUCCESS,
    VARIABLES_LIST_GET_ERROR
} from '../actions';

export const getVariablesList = () => ({
    type: VARIABLES_LIST_GET_ITEMS
})

export const getVariablesListSuccess = (items) => ({
    type:  VARIABLES_LIST_GET_SUCCESS,
    payload: items
});

export const getVariablesListError = (error) => ({
    type: VARIABLES_LIST_GET_ERROR,
    payload: error
});